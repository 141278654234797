<template>
  <BasicModal
    :action="submitForm"
    action-title="Update"
    :close-on-action="false"
    title="Update contract nickname"
    :disable-action-button="form.isLoading || !isFormValid"
  >
    <FormSubmitErrors :formName="formName" />
    <FormInput :formName="formName" fieldName="name" ref="focusElement" />
    <LoadingOverlay v-if="form.isLoading" />
  </BasicModal>
</template>

<script>

  import useForm from '@/composables/useForm'

  import FormInput from '@/components/forms/FormInput.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  import BasicModal from '@/components/modals/_BasicModal.vue'

  export default {
    components: {
      FormInput,
      BasicModal,
      LoadingOverlay,
      FormSubmitErrors,
    },
    props: {
      userContract: {
        type: Object,
        required: true,
      },
      onSuccess: {
        type: Function,
        required: true,
      },
    },
    setup() {

      // data
      const formName = 'renameUserContractForm'

      // composables
      const { form, isFormValid, focusElement } = useForm({ formName })

      return {
        form,
        formName,
        isFormValid,
        focusElement,
      }

    },
    created() {
      this.$store.dispatch('forms/UPDATE_FIELD', {
        fieldName: 'name',
        formName: this.formName,
        newValue: this.userContract.name,
        newPlaceholder: this.userContract.name,
      })
    },
    methods: {
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
      submitForm() {

        this.$store.dispatch('forms/GET_FORM_DATA', this.formName)
          .then((formData) => {

            const newUserContractData = Object.assign({
              id: this.userContract.id,
            }, formData)

            return this.$store.dispatch('api/UPDATE_USER_CONTRACT', newUserContractData)
              .then((updatedUserContract) => {
                this.onSuccess(updatedUserContract)
                this.close()
              })
              .catch(() => {
                // do nothing, template will show error
              })

          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

</style>
